/* RESPONSIBLE TEAM: team-standalone */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import type Transition from '@ember/routing/transition';
import type IntercomMessengerIntegrateController from 'embercom/controllers/apps/app/standalone/deploy/intercom-messenger/integrate';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { HandoffBehavior } from 'embercom/objects/standalone/constants';
import type Store from '@ember-data/store';
import type SunshineMultipleConversation from 'embercom/models/standalone/sunshine-multiple-conversation';

export default class Integrate extends Route {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare finStandaloneService: FinStandaloneService;

  multipleConversations?: SunshineMultipleConversation;

  queryParams = {
    section: {
      refreshModel: false,
    },
  };

  async beforeModel() {
    this.multipleConversations = await this.store.findRecord(
      'standalone/sunshine-multiple-conversation',
      this.appService.app.id,
    );
  }

  setupController(
    controller: IntercomMessengerIntegrateController,
    model: unknown,
    transition: Transition,
  ) {
    super.setupController(controller, model, transition);

    let params = this.paramsFor(this.routeName) as { section: string | null };

    controller.set('section', params.section ?? this.openSectionId);
  }

  resetController(
    controller: IntercomMessengerIntegrateController,
    isExiting: boolean,
    transition: Transition,
  ) {
    super.resetController(controller, isExiting, transition);
    if (isExiting) {
      controller.set('section', null);
    }
  }

  get openSectionId() {
    if (this.hasStandalonePlatform('zendesk')) {
      if (this.zendeskConfig?.isPendingSetupForTickets) {
        return 'hand-off';
      }

      let handoffBehaviors = [HandoffBehavior.ZendeskHybrid, HandoffBehavior.ZendeskLiveAgent];
      if (
        handoffBehaviors.includes(this.intercomMessengerConfig.handoffBehavior) &&
        this.sunshineIntegration?.isSunshineDisconnected
      ) {
        return 'hand-off';
      }

      if (
        this.intercomMessengerConfig.handoffBehavior === HandoffBehavior.ZendeskTicket &&
        !this.multipleConversations?.enabled
      ) {
        return 'zendesk-multiple-conversations';
      }

      if (!this.finStandaloneService.operatorIdentity) {
        return 'fin-identity';
      }

      return null;
    } else if (this.hasStandalonePlatform('salesforce')) {
      if (this.salesforceConfig?.isPendingSetup) {
        return 'salesforce-setup-api-connection';
      }

      if (!this.finStandaloneService.operatorIdentity) {
        return 'fin-identity';
      }

      return null;
    } else {
      return 'hand-off';
    }
  }

  get intercomMessengerConfig() {
    return this.finStandaloneService.intercomMessengerConfig;
  }

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig;
  }

  get sunshineIntegration() {
    return this.zendeskConfig?.sunshineIntegration;
  }

  get salesforceConfig() {
    return this.finStandaloneService.salesforceConfig;
  }

  hasStandalonePlatform(platform: string) {
    return this.appService.app.hasStandalonePlatform(platform);
  }
}
